<template>
  <div class="hierachy-box">
    <div>
          <TextScroll :isVis="isVis" :dataList="textArr" @handlerNotice="getAnnouncement"></TextScroll>
        </div>
    <div class="box">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="h-weight card-title">{{ $t('RewardCenter.Stistic.modular1.MTitle') }}</span>
        </div>
        <div>
          <el-row :gutter="20" style="display:flex;justify-content:space-between">
            <el-col style="display:flex" :span="10"
              ><div class="grid-content bg-purple" style="align-self:center;width:100%">
                <div class="item">
                  <div class="tt" style="width:50%">{{ $t('RewardCenter.Stistic.modular1.Registered') }}:</div>
                  <div class="val">{{ deatilData.registered_users || 0 }}</div>
                </div>
                <div class="item">
                  <div class="tt" style="width:50%">{{ $t('RewardCenter.Stistic.modular1.Recent7DaysRegistered') }}:</div>
                  <div class="val">{{ deatilData.registered_7d || 0 }}</div>
                </div>
                <div class="item">
                  <div class="tt" style="width:50%">{{ $t('RewardCenter.Stistic.modular1.PaidUsers') }}:</div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('RewardCenter.Stistic.modular1.PaidUsersTip')"
                    placement="top-start"
                  >
                    <i
                      style="color: #e6a23c"
                      class="el-icon-question qtstyle"
                    ></i>
                  </el-tooltip>
                  <div class="val" style="margin-left:2.6rem">{{ deatilData.paid_users || 0 }}</div>
                </div>
                <div class="item">
                  <div class="tt" style="width:50%">{{ $t('RewardCenter.Stistic.modular1.Layer2Registered') }}:</div>
                  <div class="val">{{ deatilData.layer2_users || 0 }}</div>
                </div>
                <div class="item">
                  <div class="tt" style="width:50%">{{ $t('RewardCenter.Stistic.modular1.Layer2RegisteredIn7Days') }}:</div>
                  <div class="val">{{ deatilData.layer2_registered_7d || 0 }}</div>
                </div>
              </div>
              <div class="ff-tip" ref="tip">
                <!-- <p class="csp">{{ $t('RewardCenter.Stistic.modular1.P1') }}</p>
                <p class="csp">{{ $t('RewardCenter.Stistic.modular1.P2') }}</p>
                <p class="csp">{{ $t('RewardCenter.Stistic.modular1.P2P') }}</p>
                <p class="csp">{{ $t('RewardCenter.Stistic.modular1.P3') }}</p>
                <p class="csp">{{ $t('RewardCenter.Stistic.modular1.P3P') }}</p>
                <p class="csp">{{ $t('RewardCenter.Stistic.modular1.P4') }}</p>
                <p class="csp">{{ $t('RewardCenter.Stistic.modular1.P4P') }}</p> -->
                <!-- <p>若付費用戶<3，則X為3 - 付費用戶，升級為S</p> -->
                <!-- <p>3<=付費用戶<6，X為6 - 付費用戶，升級為SS</p> -->
                <!-- <p>6<=付費用戶<9，X為9 - 付費用戶，升級爲SSS<p> -->
                <p class="csp">{{ $t('RewardCenter.Stistic.modular1.P1') }}<span style="color:#000">{{ objn.nums }} </span>{{ $t('RewardCenter.Stistic.modular1.P2') }}<span style="color:#000">{{ objn.levels }}</span></p>
              </div>
              </el-col>
              
            <el-col :span="12"
              ><div class="grid-content bg-purple">
                <div style="text-align: center;margin-bottom:1rem">
                  <!-- <el-button
                    size="mini"
                    v-for="(item, index) in searchFild"
                    :key="index"
                    @click="getIndex(index)"
                    :class="acIndex == index ? 'active' : ''"
                    >{{ item }}</el-button
                  > -->
                  <el-button size="mini" @click="getIndex(0)" :class="acIndex == 0 ? 'active' : ''">{{ $t('RewardCenter.Stistic.modular1.Days7') }}</el-button>
                      <el-button size="mini" @click="getIndex2(1)" :class="acIndex == 1 ? 'active' : ''">{{ $t('RewardCenter.Stistic.modular1.Days30') }}</el-button>
                      <!-- <el-button>三月</el-button> -->
                      <!-- <el-button>半年</el-button> -->
                </div>
                <StisticUser :lv1="userIncreaseData1" :lv2="userIncreaseData2" :statementDate="userIncreaseDate"></StisticUser></div></el-col>
          </el-row>
        </div>
      </el-card>
      <!--  -->
      <el-card class="box-card" style="margin-top:1rem">
        <div slot="header" class="clearfix">
          <span class="h-weight card-title">{{ $t('RewardCenter.Stistic.modular2.MTitle') }}</span>
        </div>
        <div>
          <el-row :gutter="20" style="display:flex;justify-content:space-between">
            <el-col style="display:flex" :span="8"
              ><div class="grid-content bg-purple" style="align-self:center;width:100%">
                <div class="item" style="justify-content:flex-start">
                  <div class="tt" style="width:50%;">{{ $t('RewardCenter.Stistic.modular2.RewardTotal') }}:</div>
                  <div class="val">${{ formatNum(rewardTotal) || 0 }}</div>
                  <div class="val" style="margin:0">
                    <!-- <el-tooltip
                    class="item"
                    effect="dark"
                    content=""
                    placement="top-start"
                  >
                    <i
                      style="color: #e6a23c"
                      class="el-icon-info qtstyle"
                    ></i>
                  </el-tooltip> -->
                  </div>
                </div>
                <div class="item" style="justify-content:flex-start">
                  <div class="tt" style="width:50%;">{{ $t('RewardCenter.Stistic.modular2.Reward7Days') }}:</div>
                  <div class="val">${{ formatNum(rewardTotal7Days) || 0 }}</div>
                  <div class="val" style="margin:0">
                    <!-- <el-tooltip
                    class="item"
                    effect="dark"
                    content=""
                    placement="top-start"
                  >
                    <i
                      style="color: #e6a23c"
                      class="el-icon-info qtstyle"
                    ></i>
                  </el-tooltip> -->
                  </div>
                </div>
              </div></el-col
            >
            <el-col :span="12"
              ><div class="grid-content bg-purple">
                <div style="text-align: center;margin-bottom:1rem">
                  <!-- <el-button
                    size="mini"
                    v-for="(item, index) in searchFildRw"
                    :key="index"
                    @click="getIndexRw(index)"
                    :class="acIndexRw == index ? 'active' : ''"
                    >{{ item }}</el-button
                  > -->
                  <el-button size="mini" @click="getIndexRw(0)" :class="acIndex == 0 ? 'active' : ''">{{ $t('RewardCenter.Stistic.modular2.Days7') }}</el-button>
                      <el-button size="mini" @click="getIndexRw2(1)" :class="acIndex == 1 ? 'active' : ''">{{ $t('RewardCenter.Stistic.modular2.Days30') }}</el-button>
                      <!-- <el-button>三月</el-button> -->
                      <!-- <el-button>半年</el-button> -->
                </div>
                <StisticReward :lvRw1="rewardData1" :lvRw2="rewardData2" :statementRwDate="rewardDate"></StisticReward></div></el-col>
          </el-row>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {getAgentStistic,getAgentDailyStatement,getPageNotice} from "../../api/http";
import StisticUser from "../../components/StisticUser.vue";
import StisticReward from "../../components/StisticReward.vue"
import TextScroll from "../../components/TextScroll.vue"
export default {
  components: {
      StisticUser,
      StisticReward,
      TextScroll
  },
  
  data() {
    return {
      searchFild: ["7日", "一月", "三月"],
      searchFildRw: ["7日", "一月", "三月"],
      acIndex: 0,
      acIndexRw: 0,
      deatilData: "",
      userIncreaseData1: [],
      userIncreaseData2: [],
      userIncreaseDate: [],
      rewardData1: [],
      rewardData2: [],
      rewardDate: [],
      objn: {
        nums: null,
        levels: ""
      },
      textArr: [
        // {
        //   id:1,
        //   val:'代理中心-统计'
        // }
      ],
      isVis: false
    };
  },
  computed: {
    // 奖励总额：reward_total + layer2_reward_total
    rewardTotal() {
      return (Number(this.deatilData.reward_total) + Number(this.deatilData.layer2_reward_total)).toFixed(2)
    },
    // 近7日奖励：reward_total_7d + layer2_reward_total_7d
    rewardTotal7Days() {
      return (Number(this.deatilData.reward_total_7d) + Number(this.deatilData.layer2_reward_total_7d)).toFixed(2)
    },
  },
  
  created() {
      this.reqStistic()
      this.reqStisticStatement()
    // let de = []
    // for(let i = 0; i > -7; i--) {
    //     de.push(this.get7Date(i))
    // }
    // console.log('de==>', de)
    this.gettets()
  },
  mounted() {
    
  },
  watch: {
    "deatilData.paid_users": {
      handler() {
        this.jsNums()
      }
    }
  },
  methods: {
    // 请求通告数据
    getAnnouncement(noticeType) {
      let obj = {
        uid: window.localStorage.getItem("uid"),
        type: "get_page_notice",
        // page: "fund_fund"
        page: noticeType
      }
      getPageNotice(obj).then((res) => {
        console.log("notice==>", res)
        if(res.code == 200) {
          if(res.data.length !== 0) {
            this.isVis = true
            this.textArr = res.data
          }else {
            this.isVis = false
          }
        }
      })
    },
    jsNums() {
      // 付费用户数
      let num = Number(this.deatilData.paid_users)
      // 若付費用戶<3，
      // 則X為3 - 付費用戶，升級為S
      if(num < 3) {
        this.objn.nums = 3 - num
        this.objn.levels = "S"
        return this.obj
      }
      // 3<=付費用戶<6，
      // X為6 - 付費用戶，升級為SS
      else if(3 <= num && num < 6) {
        this.objn.nums = 6 - num
        this.objn.levels = "SS"
        return this.obj
      }
      // 6<=付費用戶<9，
      // X為9 - 付費用戶，升級爲SSS
      else if(6 <= num && num <9) {
        this.objn.nums = 9 - num
        this.objn.levels = "SSS"
        return this.obj
      }
      else if(num >= 9) {
        let els = this.$refs.tip
        els.style.display = "none"
        return this.obj
      }
    },
    formatNum(value) {
      if (!value && value !== 0) return 0;

      let str = value.toString();
      let reg =
        str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, "$1,");
    },
    getDate730(num) {
        let de = []
        for(let i = 0; i >= num; i--) {
        de.push(this.get7Date(i))
    }
    return de
    },
    // 前七天
    get7Date(n) {
        // n = -7
        var n = n
        let d = new Date()
        let year = d.getFullYear()
        let mon = d.getMonth() + 1
        let day = d.getDate()
        // day = 22
        if(day <= n) {
            mon = mon - 1
        }else{
            year = year - 1
            mon = 12
        }
        d.setDate(d.getDate() + n)
        year = d.getFullYear()
        mon = d.getMonth() + 1
        day = d.getDate()
        let s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day)
        return s
    },
    getIndex(e) {
      this.acIndex = e;
      this.click7Date()
    //   if(e == 0) {
    //       this.click7Date()
    //   }else {
    //       this.click30Date()
    //   }
    },
    getIndex2(e) {
        this.acIndex = e
        this.click30Date()
    },
    getIndexRw(e) {
    //   this.acIndexRw = e;
      this.acIndex = e
      this.click7Date()
    },
    getIndexRw2(e) {
    //   this.acIndexRw = e;
      this.acIndex = e
      this.click30Date()
    },
    // 请求信息
    reqStistic() {
        let obj = {
            type: "get_agent_stistic",
            uid: localStorage.getItem('uid')
        }
        getAgentStistic(obj).then((res) => {
            console.log('stistic res==>', res)
            this.deatilData = res.data[0]
        })
    },
    // 用户增长统计模块点击七天
    click7Date() {
        // let date7 = this.getDate730(-7)
        // let start = date7.slice(0,1)
        // let end = date7.slice(-1)
        // console.log('start click==>', start)
        // console.log('end click==>', end)
        let start = ""
        let end = ""
        this.reqStisticStatement(start, end)
    },
    // 用户增长统计模块点击30天
    click30Date() {
        let date30 = this.getDate730(-30)
        let end = date30.slice(0,1)
        let start = date30.slice(-1)
        console.log('date30==>', date30)
        console.log('start click==>', start)
        console.log('end click==>', end)
        // let end = this.gettets()
        this.reqStisticStatement(start, end)
    },
    // 获取前30天
    gettets() {
        var now = new Date(); //当前日期
        var lw = new Date(now - 1000 * 60 * 60 * 24 * 30);//最后一个数字30可改，30天的意思
        var lastY = lw.getFullYear();
        var lastM = lw.getMonth()+1;
        var lastD = lw.getDate();
        var startdate=lastY+"-"+(lastM<10 ? "0" + lastM : lastM)+"-"+(lastD<10 ? "0"+ lastD : lastD);//三十天之前日期
        return startdate
        // console.log("30天==>", startdate)
    },
    // 请求统计图数据
    reqStisticStatement(start, end) {
        console.log('start==>', start)
        console.log('end==>', end)
        this.userIncreaseDate = []
        this.userIncreaseData1 = []
        this.userIncreaseData2 = []

        this.rewardData1 = []
        this.rewardData2 = []
        this.rewardDate = []
        let data = {
            type: 'get_agent_daily_statement',
            uid: localStorage.getItem('uid'),
            start: start || "",
            end: end || ''
            // start: "2022-07-23",
            // end: "2022-08-21"
        }
        getAgentDailyStatement(data).then((res) => {
            console.log('res statemnet==>==>', res)
            res.data.forEach((item) => {
                this.userIncreaseDate.push(item.date)
                this.userIncreaseData1.push(item.statement.l1.num_registered)
                this.userIncreaseData2.push(item.statement.l2.num_registered)

                this.rewardData1.push(item.statement.l1.reward_total)
                this.rewardData2.push(item.statement.l2.reward_total)
                this.rewardDate.push(item.date)
            })
        })
    }
  },
};
</script>

<style scoped lang="scss">
.ff-tip{
    width: 336px;
    align-self: center;
    padding: 10px;
    background: #f4f4f5;
    border-radius: 10px;
    font-size: 11px;
    color: #909399;
    font-weight: bold;
    text-align: center;
}
.csp{
  margin: 0;
  padding: 3px 0px;
}
.hierachy-box {
  padding-left: 2.6rem;
  height: 100%;
  background: #fff;
  .box {
    width: 83%;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  // justify-content: flex-end;
  .tt {
    // width: 166px;
    text-align: right;
    color: rgb(66,71,77);
    font-size: 14px;
    font-family: AxLLCircular, Helvetica, Arialsans-serif;
  }
  .val {
    margin-left: 3.5rem;
    font-weight: bold;
    font-size: 16px;
    color: #2D2E25;
  }
}
.active {
  background: #00bea4;
  color: #fff;
}
.h-weight{
    font-weight: bold;
}
.card-title {
  font-weight: bold;
  font-size: 1.1rem;
}
</style>