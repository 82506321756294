<template>
  <div>
    <div ref="" style="height: 340px" id="echartss"></div>
  </div>
</template>

<script>
export default {
  props: ["lv1","lv2","statementDate"],
  watch: {
      lv1() {
          this.initCharts()
      },
      lv2() {
          this.initCharts()
        //   this.legendData = [this.$t('RewardCenter.Stistic.modular1.FirstLevelUser'),this.$t('RewardCenter.Stistic.modular1.SecondaryUser')]
      },
      statementDate() {
          this.initCharts()
      },
      "$i18n.locale"(newV) {
          console.log('newV==>', newV)
          this.initCharts()
      }
  },
  data() {
    return {
    //   legendData: [this.$t('RewardCenter.Stistic.modular1.FirstLevelUser'),this.$t('RewardCenter.Stistic.modular1.SecondaryUser')],
    };
  },
  computed: {
      legendData() {
          return [this.$t('RewardCenter.Stistic.modular1.FirstLevelUser'),this.$t('RewardCenter.Stistic.modular1.SecondaryUser')]
      }
  },
  methods: {
    initCharts() {
        // let myChart = this.$echarts.init(this.$refs.commonChart);
        let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        title: {
          //   subtext: "注册用户",
        },
        legend: {
        //   data: ["直推用戶", "二級用戶"],
        data: this.legendData
        },
        xAxis: {
          type: "category",
        //   data: ["2022-08-22", "2022-08-21", "2022-08-20", "2022-08-19", "2022-08-18", "2022-08-17", "2022-08-16"],
          data: this.statementDate,
        },
        yAxis: {
          type: "value",
        },
        series: [
            // CADDEC
          {
            // data: [120, 200, 150, 80, 70, 110, 130],
            data: this.lv1,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 10, 10], // 设置柱状图圆角
                showBackground: true,
                color: "#3399FF"
              },
            },
            type: "bar",
            barWidth: '12px',
            color: ["#5046E5"],
            name: this.$t('RewardCenter.Stistic.modular1.FirstLevelUser'),
            // name: '直推用戶',
            barGap: 0,
          },
          {
            // data: [120, 200, 150, 80, 70, 110, 130],
            data: this.lv2,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 10, 10], // 设置柱状图圆角
                showBackground: true,
                color: "#CADDEC"
              },
            },
            type: "bar",
            barWidth: '12px',
            color: ["#5046E5"],
            name: this.$t('RewardCenter.Stistic.modular1.SecondaryUser'),
            // name: '二級用戶',
            barGap: 0,
          },
        ],
      }
      // 基于准备好的dom，初始化echarts实例
    let myChart = this.$echarts.init(document.getElementById("echartss"));
    // 绘制图表，this.echarts1_option是数据
    myChart.setOption(option);
    
      }

  },
  mounted() {
    this.initCharts()
  },
};
</script>