<template>
  <div>
    <div style="height: 380px" id="echartsReward"></div>
  </div>
</template>

<script>
export default {
  props: ["lvRw1","lvRw2","statementRwDate"],
  watch: {
      lvRw1() {
          this.initChartsRw()
      },
      lvRw2() {
          this.initChartsRw()
      },
      statementRwDate() {
          this.initChartsRw()
      },
      "$i18n.locale"(newV) {
          console.log('newV==>', newV)
          this.initChartsRw()
      }
  },
  data() {
    return {
     
    };
  },
  computed: {
      legendData() {
          return [this.$t('RewardCenter.Stistic.modular2.FirstLevelReward'),this.$t('RewardCenter.Stistic.modular2.SecondaryReward')]
      }
  },
  methods: {
      initChartsRw() {
          let optionRw = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        title: {
          //   subtext: "注册用户",
        },
        legend: {
        //   data: ["直推奖励", "二级奖励"],
        data: this.legendData
        },
        xAxis: {
          type: "category",
        //   data: ["2022-08-22", "2022-08-21", "2022-08-20", "2022-08-19", "2022-08-18", "2022-08-17", "2022-08-16"],
          data: this.statementRwDate
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            // data: [120, 200, 150, 80, 70, 110, 130],
            data: this.lvRw1,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 10, 10], // 设置柱状图圆角
                showBackground: true,
                color: "#3399FF"
              },
            },
            type: "bar",
            barWidth: "12px",
            color: ["#5046E5", "#CADDEC"],
            barGap: '1%',
            // name: "直推奖励",
            name: this.$t('RewardCenter.Stistic.modular2.FirstLevelReward'),
            emphasis: {
              focus: "series",
            },
          },
          {
            // data: [120, 200, 150, 80, 70, 110, 130],
            data: this.lvRw2,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 10, 10], // 设置柱状图圆角
                showBackground: true,
                color: "#CADDEC"
              },
            },
            type: "bar",
            barWidth: "12px",
            barMargin: "20px",
            color: ["#5046E5", "#CADDEC"],
            barGap: '1%',
            // name: "二级奖励",
            name: this.$t('RewardCenter.Stistic.modular2.SecondaryReward'),
            emphasis: {
              focus: "series",
            },
          },
        ],
      }
      // 基于准备好的dom，初始化echarts实例
    let myChart = this.$echarts.init(document.getElementById("echartsReward"));
    // 绘制图表，this.echarts1_option是数据
    myChart.setOption(optionRw);
      }
  },
  mounted() {
    this.initChartsRw()
  },
};
</script>